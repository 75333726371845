exports.components = {
  "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-discriminated-union-mdx": () => import("./../../../src/blog/components/Layout/Layout.tsx?__contentFilePath=/opt/build/repo/portfolio/src/blog/discriminated-union.mdx" /* webpackChunkName: "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-discriminated-union-mdx" */),
  "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-feature-branch-mdx": () => import("./../../../src/blog/components/Layout/Layout.tsx?__contentFilePath=/opt/build/repo/portfolio/src/blog/feature-branch.mdx" /* webpackChunkName: "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-feature-branch-mdx" */),
  "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-grapql-for-beginners-mdx": () => import("./../../../src/blog/components/Layout/Layout.tsx?__contentFilePath=/opt/build/repo/portfolio/src/blog/grapql-for-beginners.mdx" /* webpackChunkName: "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-grapql-for-beginners-mdx" */),
  "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-intend-to-mdx": () => import("./../../../src/blog/components/Layout/Layout.tsx?__contentFilePath=/opt/build/repo/portfolio/src/blog/intend-to.mdx" /* webpackChunkName: "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-intend-to-mdx" */),
  "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-node-script-mdx": () => import("./../../../src/blog/components/Layout/Layout.tsx?__contentFilePath=/opt/build/repo/portfolio/src/blog/node-script.mdx" /* webpackChunkName: "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-node-script-mdx" */),
  "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-website-with-chat-gpt-mdx": () => import("./../../../src/blog/components/Layout/Layout.tsx?__contentFilePath=/opt/build/repo/portfolio/src/blog/website-with-chat-gpt.mdx" /* webpackChunkName: "component---src-blog-components-layout-layout-tsx-content-file-path-src-blog-website-with-chat-gpt-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aquascapeqa-privacy-policy-tsx": () => import("./../../../src/pages/aquascapeqa/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-aquascapeqa-privacy-policy-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pausalko-privacy-policy-tsx": () => import("./../../../src/pages/pausalko/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-pausalko-privacy-policy-tsx" */)
}

